.grid {
  width: 100%!important;
  background: #9E9E9E;
/*
  max-width: 1110px;
  margin: auto;
*/
}

/* clearfix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}
.grid-sizer,
.grid-item { 
	img{
		width: 100%;
	}
	width: 16.66%; 
	@include media-breakpoint-down(lg) {
		
		width: 25%; 
    }
	
	@include media-breakpoint-down(md) {
		width: 25%; 
    }
    @include media-breakpoint-down(sm) {
		width: 50%; 
    }
    
  
}
.grid-sizer2,
.grid-item--width2 { 
	width: 33.333%; 
	@include media-breakpoint-down(lg) {
		
		width: 25%; 
    }
	@include media-breakpoint-down(md) {
		width: 25%; 
    }
    @include media-breakpoint-down(sm) {
		width: 50%; 
    }
} 
a.box{
	position: relative;
	display: block;
	z-index: 0;
	overflow: hidden;
	//transform: scale(1.005, 1.005);
	margin-left: -1px;
	margin-right: -1px;
	img{
		@include transition(all .5s ease-out);
		transform: scale(1.01, 1.01);
	}
	.desc{
		position: absolute;
		bottom: 10px;
		right: 10px;
		left: 30px;
		font-size: 18px;
		color: #FFFFFF;
		text-shadow: 0 2px 4px rgba(0,0,0,0.50);
        z-index: 2;
        text-align: right;
        line-height: 1;
        .small{
			font-size: 14px;
	        color: rgba(#fff,0.8);
        }
	}
	&:before {
        content: " ";
        position: absolute;
		@include transition(all .5s ease-out);
        @include gradient-y($start-color: transparent, $end-color: rgba(0, 0, 0, 0.5), $start-percent: 50%, $end-percent: 100%);
        left: -1px;
        top: -1px;
        bottom: -1px;
        right: -1px;
        z-index: 1;
    }
	&:hover{
		//z-index: 1;
		img{
			@include transition(all 2.5s ease-out);
			transform: scale(1.1, 1.1);
		}
		&:before {
			@include transition(all .5s ease-out);
	        @include gradient-y($start-color: rgba(0, 0, 0, 0.5), $end-color: rgba(0, 0, 0, 0.5), $start-percent: 0%, $end-percent: 100%);
	    }
		
		//box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.5);
	}  
	
}
.grid-item{
	
	&.disable{
		
		a.box{
			pointer-events: none;
			cursor: default;
			text-decoration: none;
			&:before {
				//transform: scale(1.01, 1.01);
		        z-index: 5;
				@include transition(all .5s ease-out);
		        @include gradient-y($start-color: rgba(0, 0, 0, 0.8), $end-color: rgba(0, 0, 0, 0.8), $start-percent: 0%, $end-percent: 100%);
		    }
	    }
	}
}
