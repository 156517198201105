body{
	height: 100%;
	width: 100%;
	padding-top: 70px;
}
@media (min-width: 1680px){
	.container {
	    max-width: 1440px;
	}
}
.dropdown-menu {
    max-height: 420px;
    overflow: auto;
}
.nowrap{
	white-space: nowrap;
}
.bg-cover{
    background-size: cover;
}
.bg-transparent-dark {
    position: relative;
    &::after {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(#343a40, 0.9);
        z-index: 1;
    }
    * {
        position: relative;
        z-index: 2;
    }
}
.bg-fixed {
    width: 100%;
    min-height: 40vw;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    @include media-breakpoint-down(md) {
    	background-attachment:scroll;
	}
    &.bg-transparent-dark{
	    @include media-breakpoint-down(sm) {
			background-image: none!important;
			background-color: #000;
		}
    }
}
.bg-cover{
    background-size: cover;
}
.bg-fixed-content {
    @extend .bg-fixed;
    min-height: 450px;
    @include media-breakpoint-down(md) {
    	background-attachment:scroll;
	}
}
.navbar{
	background-color: #DEDEDE;
}
.navbar-nav .nav-link{
	@include media-breakpoint-down(sm) {
	 text-align: center;
	}
}
.btn-link{
	&:hover{
		text-decoration: none;
		color: $primary!important;
	}
}
a.btn-clients{
	img{
		@include transition(all .5s ease-out);
		-webkit-filter: grayscale(1);
		filter: grayscale(1);
		opacity: 0.7;
		margin: 0 auto;
		display: block;
	}
	&:hover{
		img{
			@include transition(all .5s ease-out);
			-webkit-filter: grayscale(0);
			filter: grayscale(0);
			opacity: 1;
		}
	}
}
.btn-lg, .btn-group-lg > .btn{
	border-radius: 3rem;
}
@keyframes jump {
  0%   {transform: translate3d(0,0,0) scale3d(1,1,1);}
  
  100% {transform: translate3d(0,-30%,0) scale3d(1,1,1);}
}
.jump {
  transform-origin: 50% 50%;
  animation: jump .5s linear alternate infinite;
}
.scrollTo{
	text-align: center;
	display: block;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 50px;
	text-decoration: none;
	@include media-breakpoint-down(lg) {
		display: none;
	}
	img{
		transform-origin: 50% 50%;
		animation: jump .5s linear alternate infinite;
	}
}

.close{
	position: absolute;
	right: 15px;
	top: 15px;
}
footer{
	a{
		color: #fff;
		margin: 0 $spacer/2;
	}
	.copyright{
		font-size: 12px;
	}
}

section{
	&.py-5{
		padding-top: $spacer * 5!important;
		padding-bottom: $spacer * 5!important;
	}
	
}
.home-carousel{
	margin-top: -70px;
	 height:100vh; width:100%;
	 .carousel-inner{height:100vh; width:100%}
    .carousel-item{height:100%; width:100%}
    @include media-breakpoint-down(md) {
	 margin-top: 0px;
	 height:auto; width:100%;
	 .carousel-inner{height:auto; width:100%}
    .carousel-item{height:100%; width:100%}
	}
	
}
.filter{
	position: fixed;
	top: 68px;
	z-index: 5;
	width: 100%;
	.btn{
		font-size: 1rem;
	}
}
.projects{
	margin-top: 34px;
	@include media-breakpoint-down(sm) {
		margin-top: 63px;
	}
}
.lang{
	a{
    	color: rgba(0, 0, 0, 0.5);
    	&:hover{
			color: rgba(0, 0, 0, 0.7);
			text-decoration: none;
    	}
    	&.active{
			color: $primary;
	    }
	}
}
.search-panel{
	margin-top: -2px;
}
.projects{
	min-height: 90vh;
}